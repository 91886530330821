/*@media print {
    a[href]:after   {content: none !important;}
    #navigation     {display: none;}
    #tourStep12     {display: none;}
    #menu           {display: none;}
    #control_panel  {display: none;} 
    #pencil         {display: none;} 
    #wrapper        {border-left: none;
                     left: -10%; }
    #header         {display: none;}
    #document_icons {display: none;}
    #panel_body     {border-bottom-width: 0px;
                     border-top-width: 0px;
                     border-left-width: 0px;
                     border-right-width: 0px;}
    #not-for-print  {display: none;}
    #print-only     { right: 20%; }
    #panel_head     { text-align: left; }
    #risk-analys    { resize: horizontal;
                      overflow: auto; }
}*/

@media print {
    #navigation, #tourstep12, #menu,
    #control_panel, #pencil, #header, 
    #document_icons,
    #not-for-print  {
                     resize: horizontal; 
                     display: none;
                     border-width: 0px;
                     width : 100%;
                     height: 100%;
                     padding: 0px 0px 0px 0px;
                     margin-bottom: 0px;
                     margin-top: 0px;
                     margin-left: 0px;
                     margin-right: 0px;
                     border-radius: 0%;
                     page-break-after: always;
                    }
    #wrapper
                    {
                     resize: horizontal; 
                     border-width: 0px;
                     width : 100%;
                     height: 100%;
                     padding: 0px 0px 0px 0px;
                     margin-bottom: 0px;
                     margin-top: 0px;
                     margin-left: 0px;
                     margin-right: 0px;
                     border-radius: 0%;
                    }
    #risk-div       {
                     resize: horizontal;
                     border-width: 0px;
                     width : 100%;
                     height: 100%;
                     padding: 0px 0px 0px 0px;
                     margin-bottom: 0px;
                     margin-top: 0px;
                     margin-left: 0px;
                     margin-right: 0px;
                     border-radius: 0%;
                    }
    #chart1         {
                     position: relative;
                     padding: 0px 0px 0px 0px;
                     margin-bottom: 0px;
                     margin-top: 0px;
                     margin-left: 0px;
                     margin-right: 0px;
                     border-radius: 0%;
                    }
    [class*="col-md-6"] {
        float: left;
        padding: 0px 0px 0px 0px;
                     margin-bottom: 0px;
                     margin-top: 0px;
                     margin-left: 0px;
                     margin-right: 0px;
                     border-radius: 0%;
                     border-width: 0px;

    }
    #panel_body {
        border-width: 0px;
    }
}
